import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import PropTypes from "prop-types"

const Quote = ({ image, name, profession, quote }) => {
  return (
    <section className="container-fluid bg-dark text-white my-5 pb-5 pb-md-0"
             style={{backgroundImage: `url("${image.childImageSharp.fluid.src}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPositionX: 'right', backgroundPositionY: 'bottom'}}
    >
      <div className="container quote-image d-flex align-items-start align-items-md-center pb-5 mb-5 pb-md-0 mb-md-0">
        <div className="row py-3">
          <div className="col-12 col-md-6 offset-md-1 blockquote font-weight-bold">
            <FontAwesomeIcon className="mb-1" icon={faQuoteLeft} />
            <h4 className="d-inline mx-2">
              {quote}
            </h4>
            <FontAwesomeIcon className="mb-1" icon={faQuoteRight} />
          </div>
          <div className="col-12 col-md-6 offset-md-1 pt-2 pt-md-5">
            <span className="text-dark text-uppercase bg-white p-2">
              {name}
            </span>
            <p className="mt-3">
              - {profession}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

Quote.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  profession: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

export default Quote