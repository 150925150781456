import React from "react"
import { graphql } from "gatsby"
import Banner from "../components/global/banner"
import Layout from "../components/global/layout"
import ProfileContent from "../components/profiles/content"
import ProfileQuote from "../components/profiles/quote"
import Stories from "../components/global/stories"
import SEO from "../components/seo/seo"

export default ({ data }) => {
  const {siteUrl} = data.site.siteMetadata
  const profile = data.profilesJson
  const {
    firstParagraphImage,
    quoteImage,
    secondParagraphImage
  } = data

  return (
    <Layout>
      <SEO title={`${profile.name}`} url={`${siteUrl}${profile.fields.slug.substring(1)}`}/>
      <Banner profession={profile.profession}
              profileId={profile.fields.slug}
              titleFirstLine={profile.first_name}
              titleSecondLine={profile.last_name}
      />
      <ProfileContent images={[firstParagraphImage, secondParagraphImage]}
                      paragraphs={profile.cards}
      />
      <ProfileQuote image={quoteImage}
                    name={profile.name}
                    profession={profile.profession}
                    quote={profile.quote}
      />
      <Stories margin={5} profileId={profile.fields.slug} />
    </Layout>
  )
}

export const query = graphql`  
  query($slug: String!, $image_quote_path: String!, $image_first_paragraph_path: String!, $image_second_paragraph_path: String!) {
    site {
      siteMetadata {
        siteUrl,
      }
    },
    profilesJson(fields: { slug: { eq: $slug } }) {
      cards {
        info,
        sub_title,
        title
      },
      fields {
        slug 
      },
      first_name,
      last_name,
      name,
      profession,
      quote,
      video
    },
    quoteImage: file(relativePath: { eq: $image_quote_path }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    firstParagraphImage: file(relativePath: { eq: $image_first_paragraph_path }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    secondParagraphImage: file(relativePath: { eq: $image_second_paragraph_path}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`