import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const Content = ({ images, paragraphs }) => {
  return (
    <section className="container-fluid bg-white py-5">
      {paragraphs.map((paragraph, index) => (
        <div className="row no-gutters py-3 py-md-5" key={index}>
          {((index % 2) === 0) ? (
            <Paragraph content={paragraph} image={images[index].childImageSharp.fluid} />
          ) : (
            <ReversedParagraph content={paragraph} image={images[index].childImageSharp.fluid} />
          )}
        </div>
      ))}
    </section>
  )
}

const Paragraph = ({content, image}) => (
  <div className="col-12 col-md-10 offset-md-1">
    <div className="row">
      <div className="col-12 col-md-5 px-0">
        <Img fluid={image} />
      </div>
      <div className="col-12 pt-4 col-md-6 offset-md-1 pt-md-0">
        <h4 className="text-uppercase">{content.title}</h4>
        {content.sub_title && (<h4 className="text-uppercase">{content.sub_title}</h4>)}
        <p className="font-weight-light pt-4">
          {content.info}
        </p>
      </div>
    </div>
  </div>
)

const ReversedParagraph = ({content, image}) => (
  <div className="col-12 col-md-10 offset-md-2">
    <div className="row flex-column-reverse flex-md-row">
      <div className="col-12 pt-4 col-md-6 pt-md-0">
        <h4 className="text-uppercase">{content.title}</h4>
        <p className="font-weight-light pt-4">
          {content.info}
        </p>
      </div>
      <div className="col-12 col-md-5 offset-md-1 px-0">
        <Img fluid={image} />
      </div>
    </div>
  </div>
)

Content.propTypes = {
  images: PropTypes.array.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.shape({
    info: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
}

export default Content